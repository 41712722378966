import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from './../store'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/auth/LoginView.vue'
import NewPasswordView from '@/views/auth/NewPasswordView.vue'
import IndexUtilisateur from '@/views/admin/utilisateurs/IndexUtilisateur.vue'
import IndexProduit from '@/views/stock/produits/IndexProduit.vue'
import IndexCommande from '@/views/stock/commandes/IndexCommande.vue'
import IndexFournisseurs from '@/views/stock/fournisseurs/IndexFournisseur.vue'
import IndexBdd from '@/views/stock/bases_de_donnees/IndexBdd.vue'
import IndexRayon from '@/views/stock/rayons/IndexRayon.vue'
import IndexMouvement from '@/views/stock/mouvement/IndexMouvement.vue'
import IndexCaisse from '@/views/vente/caisse/IndexCaisse.vue'
import IndexClient from '@/views/vente/client/IndexClient.vue'
import IndexDevis from '@/views/vente/devis/IndexDevis.vue'
import IndexDashboard from '@/views/vente/ventes/IndexDashboard.vue'
import IndexConfiguration from '@/views/vente/configuration/IndexConfiguration.vue'
import IndexBilletage from '@/views/gestion/billetages/IndexBilletage.vue'
import IndexJournees from '@/views/gestion/journees/IndexJournees.vue'
import IndexGarde from '@/views/gestion/gardes/IndexGarde.vue'
import IndexInventaire from '@/views/gestion/inventaires/IndexInventaire.vue'
import IndexParametre from '@/views/admin/parametres/IndexParametre.vue'
import ShowParametrage from '@/views/admin/parametres/ShowParametrage.vue'
import IndexReservations from '@/views/vente/reservations/IndexReservation.vue'
import IndexStatistique from '@/views/gestion/statistiques/IndexStatistique.vue'
import ShowReservationCaisse from '@/views/vente/caisse/ShowReservationCaisse.vue'

const routes: Array<RouteRecordRaw> = [

  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/new-password',
    name: 'new.password',
    component: NewPasswordView
  },
  {
    path: '/parametrage',
    name: 'parametrage',
    component: ShowParametrage
  },
  {
    path: '/admin/utilisateurs',
    name: 'utilisateurs',
    component: IndexUtilisateur,
    meta: { menuModule: 'admin', sousMenu: ['utilisateur'] }
  },

  {
    path: '/stock/produits',
    name: 'produit',
    component: IndexProduit,
    meta: { menuModule: 'stock', sousMenu: ['produit'] }
  },
   {
    path: '/stock/commandes',
    name:'commande',
    component:IndexCommande,
    meta: { menuModule: 'stock', sousMenu: ['commande'] }
  },
  {
    path : '/stock/fournisseurs',
    name:'fournisseurs',
    component:IndexFournisseurs,
    meta: { menuModule: 'stock', sousMenu: ['fournisseur'] }
  },
  {
    path:'/stock/database',
    name:'database',
    component:IndexBdd,
    meta: { menuModule: 'stock', sousMenu: ['base'] }
  },
  {
    path:'/stock/rayon',
    name:'rayon',
    component:IndexRayon,
    meta: { menuModule: 'stock', sousMenu: ['rayon'] }
  },
  {
    path:'/stock/mouvement',
    name:'mouvement',
    component:IndexMouvement,
    meta: { menuModule: 'stock', sousMenu: ['mouvement'] }
  },
  {
    path:'/vente/caisses',
    name:'caisse',
    component:IndexCaisse,
    meta: { menuModule: 'vente', sousMenu: ['caisse'] }
  },
  {
    path:'/vente/caisses/reservation/:id',
    name:'caisse.reservation',
    props: true,
    component:ShowReservationCaisse,
    meta: { menuModule: 'vente', sousMenu: ['caisse'] }
  },
  {
    path:'/vente/clients',
    name:'client',
    component:IndexClient,
    meta: { menuModule: 'vente', sousMenu: ['client'] }
  },
  {
    path:'/vente/devis',
    name:'devis',
    component:IndexDevis,
    meta: { menuModule: 'vente', sousMenu: ['devis'] }
  },
  {
    path:'/vente/dashboard',
    name:'dashboard',
    component:IndexDashboard,
    meta: { menuModule: 'vente', sousMenu: ['vente'] }
  },
  {
    path:'/vente/configuration',
    name:'configuration',
    component:IndexConfiguration,
    meta: { menuModule: 'vente', sousMenu: ['configuration'] }
  },
  {
    path:'/gestion/arrete-caisse',
    name:'arrete-caisse',
    component:IndexBilletage,
    meta: { menuModule: 'gestion', sousMenu: ['caisse'] }
  },
  {
    path:'/gestion/fin-journee',
    name:'fin-journee',
    component:IndexJournees,
    meta: { menuModule: 'gestion', sousMenu: ['journee'] }
  },
  {
    path:'/gestion/garde',
    name:'garde',
    component:IndexGarde,
    meta: { menuModule: 'gestion', sousMenu: ['garde'] }
  },
  {
    path:'/gestion/inventaire',
    name:'inventaire',
    component:IndexInventaire,
    meta: { menuModule: 'gestion', sousMenu: ['inventaire'] }
   },
  {
    path:'/admin/parametres',
    name:'parametres',
    component:IndexParametre,
    meta: { menuModule: 'gestion', sousMenu: ['parametre'] }
  },
  {
    path:'/vente/reservation',
    name:'reservation',
    component:IndexReservations,
    meta: { menuModule: 'gestion', sousMenu: ['reservation'] }
  },
  {
    path:'/gestion/statistique',
    name:'statistique',
    component:IndexStatistique,
    meta: { menuModule: 'gestion', sousMenu: ['stat'] }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // const { nom_pharmacie, adresse } = store.state.pharmacie;

  if ( to.name !== 'login' && !store.getters.authenticated) next({ name: 'login' })
  else if (to.name !== 'new.password' && store.getters.authenticated && store.getters.user.must_change_password == true) next({ name: 'new.password' })
  // else if (to.name !== 'parametrage' && store.state.pharmacie == null) next({ name: 'parametrage' })
  // else if (to.meta.menuModule) {
  //   const userHabilitations: string[] = store.getters.user.habilitations;
  //   const requiredMenuHabilitations: string[] | undefined = to.meta.menuModule as string[];
  //   const requiredHabilitations: string[] | undefined = to.meta.sousMenu as string[];

  //   // Si l'utilisateur n'a pas les habilitations requises, rediriger
  //   if (requiredMenuHabilitations && !requiredMenuHabilitations.some(habilitation => userHabilitations.includes(habilitation))) {
  //     if (requiredHabilitations && !requiredHabilitations.some(habilitation => userHabilitations.includes(habilitation))) {
  //       return next({ name: 'home' });  // Rediriger vers une page non autorisée
  //     } return next({ name: 'home' });
  //   }
  // }
  // else next()
  next()
})

export default router



// import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

// // Importer tes composants de pages
// import AdminPage from '@/views/AdminPage.vue';
// import StockPage from '@/views/StockPage.vue';
// import LoginPage from '@/views/LoginPage.vue';

// const routes: Array<RouteRecordRaw> = [
//   {
//     path: '/',
//     name: 'Home',
//     component: () => import('@/views/HomePage.vue')
//   },
//   {
//     path: '/admin',
//     name: 'Admin',
//     component: AdminPage,
//     meta: { requiresAuth: true, permission: 'ADMIN' } // Ajout des métadonnées
//   },
//   {
//     path: '/stock',
//     name: 'Stock',
//     component: StockPage,
//     meta: { requiresAuth: true, permission: 'STOCK' } // Ajout des métadonnées
//   },
//   {
//     path: '/login',
//     name: 'Login',
//     component: LoginPage
//   },
//   // Autres routes...
// ];

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// });

// // Fonction pour vérifier les habilitations
// function hasPermission(permission: string): boolean {
//   const userPermissions = JSON.parse(localStorage.getItem('userPermissions') || '[]');
//   return userPermissions.some((perm: any) => perm.menu === permission);
// }

// // Garde de navigation globale
// router.beforeEach((to, from, next) => {
//   const requiresAuth = to.meta.requiresAuth;
//   const permission = to.meta.permission;

//   // Vérifier si la route nécessite une authentification
//   if (requiresAuth) {
//     const isAuthenticated = !!localStorage.getItem('userToken'); // Par exemple, vérifier si l'utilisateur est connecté

//     if (!isAuthenticated) {
//       return next({ name: 'Login' }); // Redirige vers la page de connexion si non authentifié
//     }

//     // Vérifier les habilitations
//     if (permission && !hasPermission(permission)) {
//       return next({ name: 'Home' }); // Redirige vers la page d'accueil si l'utilisateur n'a pas les habilitations
//     }
//   }

//   next(); // Passe à la route suivante si tout est bon
// });

// export default router;
