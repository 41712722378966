<template>
    <SectionVisibility libelleSection="les produits selectionnés" :isOpen="true">
        <template #section_content>
            <AsdecodeTable :key="key" noExport
                :url="baseUrl + '/api/reservation_produits?reservation_id=' + this.reservation.id" :columns="columns"
                table="reservation_produits" v-on:set-total-records="updateLines">
                <template #header_action_btns>
                    <button type="button" class="btn btn-light mr-2" @click="transformToInvoice" v-if="(isTreatable() == true) && (lines > 0)">
                        <i class="fa-solid fa-file-pdf"></i> Transformer en devis
                    </button>
                </template>
                <template #action_btns=slotProps v-if="isTreatable() == true">
                    <button type="button" class="btn btn-sm text-danger" @click="deleteLine(slotProps.data.id)">
                        <i class="fa-solid fa-trash-alt"></i>
                    </button>
                </template>
                <template #qte=slotProps>
                    <input type="number" style="width: 90px;" class="form-control" name="qte"
                        :value="slotProps.data.qte" :max="parseInt(slotProps.data.produit.qte)" min="1"
                        @change="updateReservationProduit(slotProps.data, $event)"
                        :disabled="this.reservation.status == 'Annule' || this.reservation.status == 'Termine' || this.reservation.status == 'Devis'">
                </template>
                <template #prise_en_charge=slotProps>
                    <input type="number" style="width: 90px;" class="form-control" name="prise_en_charge"
                        :value="parseFloat(slotProps.data.prise_en_charge)" min="0" max="100" step="any"
                        @change="updateReservationProduit(slotProps.data, $event)"
                        :disabled="this.reservation.status == 'Annule' || this.reservation.status == 'Termine' || this.reservation.status == 'Devis'">
                </template>
            </AsdecodeTable>
        </template>
    </SectionVisibility>
</template>

<script>
import $ from 'jquery'
import { defineComponent } from 'vue'

export default defineComponent({
    props: ['reservation'],
    data() {
        return {
            lines: 0,
            isLoading: false,
            baseUrl: this.$store.state.api_epharma_ventes,
            columns: [
                {
                    key: 'libelle',
                    label: 'PRODUIT',
                    exportable: true
                },
                {
                    key: 'qte',
                    label: 'QUANTITE',
                    format: 'slot',
                    exportable: true
                },
                {
                    key: 'prix_de_vente',
                    label: 'COÛT',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'prise_en_charge',
                    label: '% TAUX',
                    format: 'slot',
                    exportable: true
                },
            ]
        }
    },
    methods: {
        createFacture() {
            this.isLoading = true
            let FactureForm = {
                client: this.reservation.client,
                reservation_id: this.reservation.id,
                net_a_payer: this.reservation.montant,
                type: 'Devis',
                created_by: this.$store.getters.user.fullname
            }
            this.axios.post(this.baseUrl + '/api/factures/', FactureForm).then((response) => {
                if (response.data.success == true) {
                    this.isLoading = false
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                    this.isLoading = false
                }
            })
        },
        updateReservationProduit(line, event) {
            this.isLoading = true
            let form = {}

            if (event.target.name == 'qte') {
                form = {
                    'qte': event.target.value,
                    'produit_qte': line.produit.qte,
                    'cout_total': parseInt(event.target.value) * parseFloat(line.prix_de_vente)
                }
            }
            else if (event.target.name == 'prise_en_charge') {
                let total = parseInt(line.qte) * parseFloat(line.prix_de_vente)
                form = {
                    'prise_en_charge': event.target.value,
                    'cout_total': total - ( total * (parseFloat(event.target.value)/100))
                }
            }
            
            this.axios.post(`${this.baseUrl}/api/reservation_produits/${line.id}/update`, form).then(response => {
                this.isLoading = false
                if (response.data.success === true) {
                    $('#refreshreservationmontant').click()
                    $('#refreshreservation_produits').click()
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        transformToInvoice(){
            this.$emit('switch-to-devis')
            this.createFacture()
        },
        deleteLine(id) {
            if (!window.confirm("Etes vous sur de vouloir retirer ?")) {
                return
            }
            this.isLoading = true
            this.axios.post(this.baseUrl + '/api/reservation_produits/' + id + '/destroy').then((response) => {
                this.isLoading = false
                if (response.data.success === true) {
                    this.$emit('product-removed')
                    $('#refreshreservation_produits').click()
                    $('#refreshreservationmontant').click()
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 20000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        updateLines(lines){
            this.lines = lines
            this.$emit('nb_products', lines)
        },
        isTreatable (){
            if (this.reservation.status != 'Termine' && this.reservation.status != 'Annule' && this.reservation.status != 'Devis') {
                return true
            } else return false
        }
    },
})
</script>