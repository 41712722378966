<template>

                <div class="card card-body my-3">
                    <p class="h5">Paramètre générales</p>
                    <p class="h5">Enregistrez les informations de la pharmacie.</p>
                    <form @submit.prevent="saveCompanyInfo">
                        <div class="ml-3">
                            <div class="form-row">
                                <div class="form-group col-md-4">
                                    <label>Nom:</label>
                                    <input type="text" class="form-control" v-model="formData.nom_pharmacie" required>
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Adresse:</label>
                                    <input type="text" class="form-control" v-model="formData.adresse" required>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">Boite Postale:</label>
                                    <input type="text" class="form-control" v-model="formData.bp">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">Capital:</label>
                                    <input type="number" class="form-control" v-model="formData.capital">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Statut juridique:</label>
                                    <input type="text" class="form-control" v-model="formData.statut_juridique">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">RCCM:</label>
                                    <input type="text" class="form-control " v-model="formData.rccm">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">NIF:</label>
                                    <input type="text" class="form-control " v-model="formData.nif">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">Tel 1:</label>
                                    <input type="number" class="form-control" v-model="formData.telephone1" required>
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">Tel 2:</label>
                                    <input type="number" class="form-control" v-model="formData.telephone2">
                                </div>
                                <div class="form-group col-md-4">
                                    <label>Email:</label>
                                    <input type="text" class="form-control" v-model="formData.email">
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="">Site web:</label>
                                    <input type="text" class="form-control" v-model="formData.site_web">
                                </div>

                                <!-- <div class="form-group col-md-4">
                            <label for="">Devise:</label>
                            <div>
                                <select type="t" v-model="formData.devise">
                                    <option value="">FCFA</option>
                                    <option value="">$</option>
                                    <option value="">£</option>
                                </select>
                            </div>
                        </div> -->
                            </div>
                        </div>
                        <button type="submit" class="btn btn-bleu2 text-right">
                            Enregistrer
                            <span v-if="isLoading == true" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>
                        </button>
                    </form>
                </div>

</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    data() {
        return {
            baseUrl: this.$store.state.api_epharma_comptes,
            isLoading: false,
        }
    },
    computed: {
        formData: {
            get() {
                return this.$store.getters.pharmacie || {};  // Accéder aux données via Vuex
            },
            set(value) {
                this.$store.commit('setPharmacieDatas', value);  // Mettre à jour Vuex quand on modifie les inputs
            },
        },
    },
    methods: {
        async saveCompanyInfo() {
            this.isLoading = true
            this.axios.post('/api/pharmacie-info', this.formData).then(response => {
                this.isLoading = false
                if (response.data.success === true) {
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                    // this.$router.push({ name: 'home' })
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },


    },

})
</script>