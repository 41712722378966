<template>
    <div class="card mx-auto border-bleugain ">
        <div class="card-body row pt-2 pb-0 px-3">
            <div class="card-gain col">
                <h4 class="border-bottom text-bleugain">{{ moneyFormat(this.left_val) }}</h4>
                <p class="text-right font-weight-bold">{{ this.left_label }}</p>
            </div>
            <div class="card-gain col">
                <h4 class="border-bottom text-bleugain">{{ moneyFormat(this.right_val) }}</h4>
                <p class="text-right font-weight-bold">{{ this.right_label }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"MoneyCounter",
        props: [ 'left_val', 'left_label', 'right_val', 'right_label' ],
        methods: {
            moneyFormat(varMoney) {
                if (varMoney) return new Intl.NumberFormat('de-DE').format(varMoney) + ' FCFA'
            },
        },
    }
</script>