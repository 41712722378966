<template>
  <div class="d-flex justify-content-between mb-3">
    <div><strong>Client : </strong>{{ datas.client }}</div>
    <div><strong>Date :</strong> {{ dateFormat(datas.created_at) }}</div>
    <div><strong>Total TTC :</strong> {{ moneyFormat(datas.reservation.montant) }}</div>
  </div>


  <div class="mb-5">
    <table class="table table-sm table-bordered mb-3">
      <thead>
        <tr>
          <th>LIBELLE</th>
          <th>Qté</th>
          <th>P.U</th>
          <th>Coût TTC</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="produit in produits" :key="produit.id">
          <td>{{ produit.libelle }}</td>
          <td>{{ produit.qte }}</td>
          <td>{{ moneyFormat(produit.prix_de_vente) }} </td>
          <td>{{ moneyFormat(parseFloat(produit.prix_de_vente) * parseInt(produit.qte)) }} </td>
        </tr>
      </tbody>
    </table>


    <!-- <div class="border justify-content-center">
      <div class="border ">TVA</div>
      <div class="border ">100 000 FCFA</div>
    </div>
    <div class="border justify-content-center">
      <div class="border ">CSS</div>
      <div class="border ">100 000 FCFA</div>
    </div> -->
  </div>

  <div class="d-flex justify-content-end">
    <button @click="downloadPDF" class="btn btn-outline-dark mr-2">
      <i class="fa-solid fa-download mr-1"></i> Télécharger
    </button>
    <button @click="createNewReservation()" class="btn btn-outline-primary">
      <i class="fa-solid fa-circle-chevron-down mr-1"></i> Valider le devis 
      <span v-if="isLoadingNewReservation == true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
  </div>

  <!-- Zone cachée pour le PDF -->
  <div hidden>
    <div id="factureContent" class="container-fluid">
      <!-- Informations de l'entreprise -->
      <div class=""><img src="/img/icons/epharma.png " alt="" style="width: 100px;" id="imglogo2epharma"></div>
      <div class="row d-flex justify-content-between">
        <div class="col-5 ">
          <p class="">
            <strong>Email:</strong> pharmacie.testrouteur@gmail.com
            <br><strong>Adresse:</strong> SING Post Epharma
            <br><strong>Téléphone:</strong> 062000000
          </p>
        </div>
        <div class="col-5">
          <p class="">
            <strong>Client: </strong> {{ datas.client }}
            <br><strong>Téléphone:</strong> 062000000
            <br><strong>Adresse:</strong> Centre-Ville, Libreville Gabon
          </p>
        </div>

      </div>

      <!-- En-tête de la facture -->
      <div class="invoice-header">
        <div class="text-center mt-5">
          <h3>FACTURE PROFORMA N°{{ datas.id }}
          </h3>
        </div>
        <div class="text-right">
          <p>Date: {{ dateFormat(datas.created_at) }}</p>
        </div>
      </div>

      <!-- Tableau des produits -->
      <div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>LIBELLE</th>
              <th>Qté</th>
              <th>P.U</th>
              <th>Coût TTC</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="produit in produits" :key="produit.id">
              <td>{{ produit.libelle }}</td>
              <td>{{ produit.qte }}</td>
              <td>{{ moneyFormat(produit.prix_de_vente) }} </td>
              <td>{{ moneyFormat(parseFloat(produit.prix_de_vente) * parseInt(produit.qte)) }}</td>
            </tr>
            <!-- <tr>
              <th colspan="3">TVA</th>
              <td colspan="2">{{ produit.tva }}</td>
            </tr>
            <tr>
              <td colspan="3">CSS</td>
              <td colspan="2">{{ produit.css }}</td>
            </tr>
            <tr>
              <td colspan="3">Montant total</td>
              <td colspan="2">{{ produit.cout_total }}</td>
            </tr> -->
          </tbody>
        </table>
        <p><strong>Nombres de lignes:</strong></p>
        <p><strong>Arrété la présenre facture à la somme de :</strong> {{ moneyFormat(datas.reservation.montant) }} </p>


      </div>

      <!-- Pied de page -->

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import html2pdf from 'html2pdf.js';

export default {
  inject: ['dialogRef'],
  data() {
    return {
      isLoadingNewReservation: false,
      datas: this.dialogRef.data,
      baseUrl: this.$store.state.api_epharma_ventes,
      produits: [],
      total_tva: 0,
      total_css: 0
    };
  },
  mounted() {
    this.getProduits()
  },
  methods: {
    getProduits() {
      this.isLoading = true
      this.axios.get(this.baseUrl + '/api/reservation_produits?reservation_id=' + this.datas.reservation.id).then(response => {
        this.isLoading = false
        this.produits = response.data.data
      }).catch(() => {
        this.isLoading = false
        this.$toast.add({
          severity: 'error',
          summary: 'Probleme de connexion',
          detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
          life: 5000
        });
      })
    },
    dateFormat(varDate) {
      if (varDate != '') {
        const newDate = new Date(varDate)
        if (newDate) return moment(newDate).format('DD/MM/YYYY')
      }
    },
    moneyFormat(varMoney) {
      if (varMoney) return new Intl.NumberFormat('de-DE').format(varMoney) + ' FCFA'
    },
    downloadPDF() {
      this.isLoading = true
      html2pdf().set({
        pagebreak: { mode: 'avoid-all' },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        margin: [0.3, 0.3],
        filename: "Facture proforma n°" + this.datas.id,
        html2canvas: { scale: 2 },
        image: { type: 'jpeg', quality: 0.98 },
      }).from(document.getElementById("factureContent"))
        .toPdf()
        .get('pdf')
        .then(function (pdf) {
          var totalPages = pdf.internal.getNumberOfPages();
          pdf.setFontSize(10);
          pdf.setTextColor(150);
          for (var i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.text(' S.A.R.L au capital de xxxxxxxxx FCFA | xxxxx - BP: xxxxxxxxx | Tel: +241 xxxxxx xxxxxxx ' + "\n" + 'RCCM: xxxxxxxxxxxx / NIF: xxxxxxxxxxxxx  |Email: xxxxxxxxx / Site web: xxxxxxxxxx', pdf.internal.pageSize.getWidth() - 0.50, pdf.internal.pageSize.getHeight() - 0.50, { align: 'right' });
          }
        })
        .save();
      setTimeout(() => {
        this.isLoading = false

      }, 5000)
    },
    createNewReservation() {
      this.isLoadingNewReservation = true
      let form = {
        user: this.$store.getters.user.fullname
      }
      this.axios.post(this.baseUrl + '/api/reservation/facture/' + this.datas.reservation.id, form).then(response => {
        this.isLoadingNewReservation = false
        if (response.data.success === true) {
          this.$toast.add({
            severity: 'success',
            detail: response.data.message,
            life: 3000
          });
          this.dialogRef.close()
        }
        else {
          response.data.errors.forEach(element => {
            this.$toast.add({
              severity: 'warn',
              summary: 'Oups !',
              detail: element,
              life: 7000
            });
          });
        }
      }).catch(() => {
        this.isLoadingNewReservation = false
        this.$toast.add({
          severity: 'error',
          summary: 'Probleme de connexion',
          detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
          life: 5000
        });
      })
    }
  }
}
</script>

<style>
/* Style pour le texte au-dessus du tableau */
/* .row p {
  margin-bottom: 4px; */
/* Diminue l'espace entre les paragraphes */
/* line-height: 1.2; */
/* Diminue l'espacement entre les lignes */
/* } */

/* Autres styles déjà présents */
/* .table th,
.table td {
  padding: 8px;
} */

/* .table th {
  text-align: left;
} */

/* Style pour l'en-tête et le pied de page de la facture */
.invoice-header {
  margin-bottom: 20px;
}

/* .footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.left-footer,
.right-footer {
  text-align: left;
  margin-top: 50px;
}

.right-footer {
  text-align: right;
} */

.imglogo2epharma {
  margin-bottom: 20px;
  padding: 8px;
  display: flex;
}

/* .row {
  margin-bottom: 8px;
  padding: 8px;
} */
</style>