<template>
    <div class="container">
        <div class="d-flex justify-content-center">
            <InfosPharmacie></InfosPharmacie>
        </div>

    </div>
</template>
<script>
import { defineComponent } from 'vue';
import InfosPharmacie from './InfosPharmacie.vue'

export default defineComponent({
    components: { InfosPharmacie }

})
</script>