<template>
    <EpharmaTemplate>
        <template #page_title> VENTES</template>
        <template #page_counter>
            <i class="fas fa-money-bill-wave"></i> {{ lines }}
        </template>
        <template #content>
            <div class="border bg-light rounded p-3 mx-auto mb-3">
                <div class="row">
                    <form @submit.prevent="venteFilter" class="form-row col-md-7">
                        <div class="form-group col-md-4 ">
                            <div>
                                <small class="font-weight-bold">Rechercher par date</small>
                                <div>
                                    <label></label>
                                    <input class="form-control form-control-3" v-model="filterForm.debut" type="date"
                                        style="padding-top: 18px; padding-bottom: 18px;
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-4 ">
                            <div>
                                <small class="font-weight-bold pl-3"></small>
                                <div>
                                    <label></label>
                                    <input class="form-control form-control-sm" v-model="filterForm.fin" type="date"
                                        style="padding-top: 18px; padding-bottom: 18px;
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;">
                                </div>
                            </div>
                        </div>
                        <!-- <div class="form-group col-3">
                            <div>
                                <small class="font-weight-bold">Rechercher par statut</small>
                                <div>
                                    <label></label>
                                    <select class="form-control form-control-sm" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;" v-model="filterForm.statut">
                                        <option value="Toutes les ventes">Toutes les ventes</option>
                                        <option value="validée">validée</option>
                                        <option value="annulée">Annulée</option>
                                    </select>
                                </div>
                            </div>

                        </div> -->
                        <div class="col-md mt-5 d-flex">
                            <button type="submit" class="btn">
                                <i class="fa-solid fa-circle-check text-success" style="font-size: 1.6rem;"
                                    title="Appliquer le filtrage"></i>
                            </button>
                            <button type="button" class="btn" @click="removeFilter">
                                <i class="fa-solid fa-circle-minus" style="font-size: 1.6rem; color: #28a745;"
                                    title="Annuler le filtrage"></i>
                            </button>
                        </div>
                    </form>
                    <div class="col-md-5">
                        <p>Rechercher par mot clé</p>
                        <searchByKeyword class="mb-3" v-on:set-keyword-filter="searchLines" />
                    </div>
                </div>

            </div>
            <button hidden id="refreshcounter" type="button" @click="refreshCounters">Raffraichir le compteur</button>
            <MoneyCounterDeux v-if="lines > 0 " :key="keyCounter" :left_val="total_client" :left_val1="total_pc" left_label="CLIENT CFA-ASSURANCE CFA"
                :right_val="total_ca" :right_val1="total_ht" :right_val2="total_tva" :right_val3="total_css" right_label="CHIFFRE D'AFFAIRE TOTAL-HT-TVA-CSS" /><br>

            <AsdecodeTable :key="key" :newKeyWord="keyWord" :url="baseUrl + url" :columns="columns" :table="table"
                v-on:set-total-records="updateLines"  @get-table-url="refreshCounters">
                <template #header_action_btns>
                    <!-- <button class="btn btn-light mr-2" @click="getDatas()">
                        Recapitulatif CNAMGS
                    </button> -->
                </template>
                <template #action_btns=slotProps>
                    <button class="btn btn-sm btn-light m-1" @click="openShowModal(slotProps.data)">
                        <i class="fa-solid fa-eye"></i>
                    </button>
                    <button class="btn btn-sm btn-light m-1" @click="openShowFacture(slotProps.data)">
                        <i class="fa-solid fa-file-lines"></i>
                    </button>
                    
                </template>
               
                <template #reservation_id=slotProps>
                    <span class="mr-1">{{ slotProps.data.reservation_id }},</span> {{
                        dateFormat(slotProps.data.created_at) }}
                </template>
            </AsdecodeTable>
            <p class="mt-5 mb-3 font-weight-bold">Produits vendus dans la période</p>
            <form class="border bg-white rounded p-3 mx-auto mb-3" style="opacity: 0.7;">
                <div class="form-row">
                    <div class="form-group col-lg-3">
                        <div>
                            <small class="font-weight-bold">Rechercher par libelle</small>
                            <div>
                                <label></label>
                                <input class="form-control form-control-sm" type="text" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;" placeholder="Doliprane" v-model="libelle_keyword"
                                    @input="key2++">
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <AsdecodeTable :key="key2" :newKeyWord="libelle_keyword"
                :url="baseUrl + '/api/reservation_produits/vendus?from_period_debut=' + this.filterForm.debut + '&from_period_fin=' + this.filterForm.fin"
                :columns="columns1">
                <template #produit="slotProps">
                    {{ slotProps.data.produit.cip }}
                </template>
                <template #id="slotProps">
                    <button class="btn btn-sm btn-primary">
                        <i class="fa-solid fa-magnifying-glass mr-1"></i> Trouver les ventes
                    </button>
                </template>
            </AsdecodeTable>
        </template>
    </EpharmaTemplate>
</template>

<script>
import * as XLSX from 'xlsx';
import moment from 'moment'
import MoneyCounterDeux from '@/components/MoneyCounterDeux.vue';
import ShowVente from './ShowVente.vue'
import ShowFacture from './ShowFacture.vue';
export default {
    inject: ['dialogRef'],
    components: { MoneyCounterDeux },
    data() {
        return {
            lines: 0,
            table: 'ventes',
            baseUrl: this.$store.state.api_epharma_ventes,
            defaultUrl: '/api/ventes',
            url: '/api/ventes',
            columns: [
                {
                    key: 'caisse',
                    label: 'CAISSE',
                    exportable: true
                },
                {
                    key: 'user',
                    label: 'VENDEUSE',
                    exportable: true
                },
                {
                    key: 'client',
                    label: 'CLIENTS',
                    exportable: true
                },
                {
                    key: 'reservation_id',
                    label: 'RESERVATION',
                    format: 'slot',
                    exportable: true
                },
                {
                    key: 'total',
                    label: 'TTC',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'ht',
                    label: 'HT',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'tva',
                    label: 'TVA',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'css',
                    label: 'CSS',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'total',
                    label: 'TOTAL CLIENT',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'total_prise_en_charge',
                    label: 'TOTAL PRISE EN CHARGE',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'statut',
                    label: 'STATUT',
                    format: 'state',
                    exportable: true
                }
            ],
            total_client: 0,
            total_pc: 0,
            total_tva: 0,
            total_css: 0,
            total_ht: 0,
            total_ca: 0,
            key: 1,
            keyCounter: 1,
            keyWord: '',
            filterForm: {
                debut: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                fin: moment().add(1, 'days').format('YYYY-MM-DD')
            },
            columns1: [
                {
                    key: 'produit',
                    label: 'CIP',
                    format: 'slot',
                    exportable: true
                },
                {
                    key: 'libelle',
                    label: 'LIBELLE',
                    exportable: true
                },
                {
                    key: 'qte_vendus',
                    label: 'QUANTITE VENDU',
                    exportable: true
                },
                {
                    key: 'qte_actu',
                    label: 'STOCK ACTUEL',
                    exportable: true
                },
                {
                    key: 'id',
                    label: 'VENTE',
                    format: 'slot',
                    exportable: true
                },
            ],
            datas: [],
            isLoading: false

        }
    },
    created() {
        this.venteFilter()
    },
    mounted() {
        this.getTotalAssurance()
        this.getTotalChiffreAffaire()
    },
    methods: {
        async refreshCounters(url) {
            await this.getTotalClientSum(url)
            await this.getPriseEnChargeSum(url)
            await this.getCASum(url)
            await this.getHTSum(url)
            await this.getTVASum(url)
            await this.getCssSum(url)
        },
        //fonction pour empecher les erreurs lors du filtre
        checkUrlFormat(link) {
            if (link !== undefined && link.includes('?')) link = link + '&'
            else link = link + '?'
            return link
        },
        dateFormat(varDate) {
            if (varDate != '') {
                const newDate = new Date(varDate)
                if (newDate) return moment(newDate).format('DD/MM/YYYY HH:mm:ss')
            }
        },
        openShowModal(objData){
            this.$dialog.open(ShowVente, {
                props: {
                    header: "Vente N°" + objData.id + " du " + this.dateFormat(objData.created_at),
                    style: {
                        width: '50vw',
                    },
                    modal: true
                },
                data: objData
            });

        },
        openShowFacture(objData){
            this.$dialog.open(ShowFacture, {
                props: {
                    header: "Facture pour la vente N°" + objData.id  + " du " + this.dateFormat(objData.created_at),
                    style: {
                        width: '50vw',
                    },
                    modal: true
                },
                data: objData
            });
            
        },
        getTotalAssurance() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/' + this.table + '?column_sum=prix_achat').then(response => {
                this.isLoading = false
                this.total_pa = response.data
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getTotalChiffreAffaire() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/' + this.table + '?column_sum=prix_de_vente').then(response => {
                this.isLoading = false
                this.total_pv = response.data
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        searchLines(keyword) {
            this.keyWord = keyword
            this.key++
        },
        checkUrlFormat(link) {
            if (link !== undefined && link.includes('?')) link = link + '&'
            else link = link + '?'
            return link
        },
        venteFilter() {
            let newUrl = this.defaultUrl
            // if (this.filterForm.type) {
            //     newUrl = this.checkUrlFormat(newUrl) + 'type=' + this.filterForm.type
            // }

            if (this.filterForm.debut) {
                newUrl = this.checkUrlFormat(newUrl) + 'from_period_debut=' + this.filterForm.debut
            }

            if (this.filterForm.fin) {
                newUrl = this.checkUrlFormat(newUrl) + 'from_period_fin=' + this.filterForm.fin
            }
            if (this.filterForm.statut) {
                newUrl = this.checkUrlFormat(newUrl) + 'statut=' + this.filterForm.statut
            }

            this.url = newUrl
            this.key++
            this.key2++
        },
        getDatas() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/' + this.table).then(response => {
                this.datas = response.data.data
                this.$nextTick(() => {
                    // Une fois les données affichées, générer le PDF
                    // this.key++
                    this.exportToExcel()
                });

                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        exportToExcel() {
            this.datas
            const worksheet = XLSX.utils.json_to_sheet(this.datas);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Ventes');

            //     // Générer un fichier Excel
            XLSX.writeFile(workbook, this.table + '.xlsx');
        },
        removeFilter() {
            this.filterForm = {
                debut: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                fin: moment().add(1, 'days').format('YYYY-MM-DD')
            },
                this.url = this.defaultUrl
            this.key++
        },
        updateLines(nb_lines) {
            this.lines = nb_lines
        },
        getTotalClientSum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=total').then(response => {
                this.isLoading = false
                this.total_client = response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getPriseEnChargeSum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=total_prise_en_charge').then(response => {
                this.isLoading = false
                this.total_pc= response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getCASum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=total').then(response => {
                this.isLoading = false
                this.total_ca = response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getHTSum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=ht').then(response => {
                this.isLoading = false
                this.total_ht = response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getTVASum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=tva').then(response => {
                this.isLoading = false
                this.total_tva = response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getCssSum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=css').then(response => {
                this.isLoading = false
                this.total_css = response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
      
    }
}


</script>