<template>
  <EpharmaTemplate>
      
      <template #page_title> FOURNISSEURS </template>
      <template #page_counter>
          <i class="fas fa-dolly-flatbed"></i> {{ lines }}
      </template>
      <template #content>
        <searchByKeyword class="mb-3" v-on:set-keyword-filter="searchLines" />
            <AsdecodeTable :key="key" :url="baseUrl + '/api/' + table" :columns="columns" :table="table" :newKeyWord="keyWord"  v-on:set-total-records="updateLines">
                <template #header_action_btns>
                    <button class="btn btn-light mr-2" @click="openCreateModal()">
                        Nouveau fournisseur
                    </button>
                </template>
                <template #action_btns=slotProps>
                    <div class="d-flex">
                        <button class="btn btn-sm btn-light m-1" @click="openShowModal(slotProps.data)">
                            <i class="fas fa-edit"></i>
                        </button>
                    </div>
                </template>
            </AsdecodeTable>
        </template>
  </EpharmaTemplate>
</template>

<script>
  import { defineComponent, defineAsyncComponent } from 'vue';
  
  const CreateFournisseur = defineAsyncComponent(() => import('./CreateFournisseur.vue'));
  const EditFournisseur = defineAsyncComponent(() => import('./EditFournisseur.vue'));
  const ShowFournisseur = defineAsyncComponent(() => import('./ShowFournisseur.vue'));
  export default defineComponent({
      
      data() {
      return {
        lines: 0,
        table: 'fournisseurs',
        baseUrl: this.$store.state.api_epharma_stock,
          columns: [
              {
                  key: 'libelle',
                  label: 'NOM',
                  exportable: true
              },
              {
                  key: 'telephone',
                  label: 'TÉLÉPHONE',
                  exportable: true
              },
             
              {
                  key: 'email',
                  label: 'EMAIL',
                  exportable: true
              },
              {
                  key: 'adresse',
                  label: 'ADRESSE',
                  exportable: true
              },
              {
                  key: 'siteurl',
                  label: 'SITE INTERNET',
                  exportable: true
              }
              
          ],
            key: 1,
            keyWord: ''
      }
  },
  methods: {
        openCreateModal() {
            this.$dialog.open(CreateFournisseur, {
                props: {
                    header: "Nouveau fournisseur",
                    style: {
                        width: '80vw',
                    },
                    modal: true
                }
            });
        },
        openCreateModalEditFounisseur() {
            this.$dialog.open(EditFournisseur, {
                props: {
                    header: "Nom",
                    style: {
                        width: '80vw',
                    },
                    modal: true
                }
            });
        },
        openShowModal(objData) {
            this.$dialog.open(ShowFournisseur, {
                props: {
                    header: "Fournisseur N°"+objData.id,
                    style: {
                        width: '60vw',
                    },
                    modal: true
                },
                data: objData
            });
        },
        updateLines(nb_lines) {
            this.lines = nb_lines
        },
        searchLines(keyword) {
            this.keyWord = keyword
            this.key++
        }
    }
  })
</script>
