<template>
    <EpharmaTemplate>
        <template #page_title> COMMANDES </template>
        <template #page_counter>
            <i class="fas fa-shipping-fast"></i> {{ lines }}
        </template>
        <template #content>
            <MoneyCounter :key="keyCounter" :left_val="total_pa" left_label="COÛT D'ACHAT" :right_val="total_pv"
            right_label="CHIFFRE D'AFFAIRE POTENTIEL" class="shadow bg-[#ccc]" />
            <div class="bg-white row border shadow-md rounded p-1 mb-5 mx-auto d-flex align-items-center">
                <div class="col-md-6">
                    <p class="mb-3"><small>Rechercher par date</small></p>
                    <form @submit.prevent="periodFilter" class="form-row">
                        <div class="col-md">
                            <input class="form-control form-control-sm" type="date" v-model="filterForm.debut">
                        </div>
                        <div class="col-md">
                            <input class="form-control form-control-sm " type="date" v-model="filterForm.fin">
                        </div>
                        <div class="col-md-3">
                            <button type="submit" class="btn">
                                <i class="fa-solid fa-circle-check text-success" style="font-size: 1.6rem;"
                                    title="Appliquer le filtrage"></i>
                            </button>
                            <button type="button" class="btn" @click="removeFilter">
                                <i class="fa-solid fa-circle-minus" style="font-size: 1.6rem; color: #28a745;"
                                    title="Annuler le filtrage"></i>
                            </button>
                        </div>
                        <div class="col-md-6">
                            <p class="mb-1"><small>Rechercher par fournisseur</small></p>
                            <select class="form-control" v-model="filterForm.fournisseur_libelle">
                                <option v-for="fournisseur in fournisseurs" :key="fournisseur.libelle" :value="fournisseur">
                                    {{ fournisseur.libelle }}</option>
                            </select>
                        </div>
                    </form>
                </div>

                <div class="col-md-6">
                    <p class="mb-3"><small>Rechercher par mot clé</small></p>
                    <searchByKeyword class="mb-3" v-on:set-keyword-filter="searchLines" />
                </div>

            </div>
          

            <AsdecodeTable :key="key" :newKeyWord="keyWord" :url="baseUrl + url" :columns="columns" :table="table"
                v-on:set-total-records="updateLines">
                <template #header_action_btns>
                    <button class="btn btn-light btn-sm mr-2" @click="openCreateModal()">
                        <i class="fa-solid fa-plus"></i> Nouvelle commande
                    </button>
                </template>
                <template #action_btns=slotProps>
                    <button class="btn btn-sm btn-light m-1" @click="openShowModal(slotProps.data)">
                        <i class="fas fa-edit"></i>
                    </button>
                </template>
                <template #status=slotProps>
                    <span class="statut " :class="[slotProps.data.status == 'SUCCESS' ? 'termine' : 'encours']">{{
                        slotProps.data.status }}</span>
                </template>
            </AsdecodeTable>
        </template>
    </EpharmaTemplate>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import MoneyCounter from '@/components/MoneyCounter.vue';
const CreateCommande = defineAsyncComponent(() => import('./CreateCommande.vue'));
const ShowCommande = defineAsyncComponent(() => import('./ShowCommande.vue'));

export default defineComponent({
    components: { MoneyCounter },
    data() {
        return {
            lines: 0,
            table: 'commandes',
            baseUrl: this.$store.state.api_epharma_stock,
            defaultUrl: '/api/commandes',
            url: '/api/commandes',
            columns: [
                {
                    key: 'numero',
                    label: 'NUMERO',
                    exportable: true
                },
                {
                    key: 'created_at',
                    label: 'DATE DE CREATION',
                    format: 'date',
                    exportable: true
                },
                {
                    key: 'fournisseur_libelle',
                    label: 'FOURNISSEUR',
                    exportable: true
                },
                {
                    key: 'facture',
                    label: 'N°FACTURE',
                    exportable: true
                },
                {
                    key: 'status',
                    label: 'STATUT',
                    format: 'slot',
                    exportable: true
                },
                {
                    key: 'total_achat_cfa',
                    label: 'PRIX D\'ACHAT ',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'created_by',
                    label: 'CREE PAR',
                    exportable: true
                },
                {
                    key: 'ended_with',
                    label: 'TERMINE PAR',
                    exportable: true
                },
            ],
            fournisseurs: [],
            total_pa: 0,
            total_pv: 0,
            key: 1,
            keyCounter: 1,
            keyWord: '',
            filterForm: {},
        }
    },
    mounted() {
        this.getFournisseurs()     
        this.calculerCoutTotal()
    },
    methods: {
        async refreshCounters(url) {
            await this.getCoutAchatSum(url)
            await this.getChiffreAffSum(url)
        },
        checkUrlFormat(link) {
            if (link !== undefined && link.includes('?')) link = link + '&'
            else link = link + '?'
            return link
        },
        periodFilter() {
            let newUrl = this.defaultUrl

            if (this.filterForm.debut) {
                newUrl = this.checkUrlFormat(newUrl) + 'from_period_debut=' + this.filterForm.debut
            }

            if (this.filterForm.fin) {
                newUrl = this.checkUrlFormat(newUrl) + 'from_period_fin=' + this.filterForm.fin
            }
            if (this.filterForm.fournisseur_libelle) {
                newUrl = this.checkUrlFormat(newUrl) + 'fournisseur_libelle=' + this.filterForm.fournisseur_libelle
            }
            this.url = newUrl
            this.key++
        },
        removeFilter() {
            this.filterForm = {}
            this.url = this.defaultUrl
            this.key++
        },
        openCreateModal() {
            this.$confirm.require({
                group: 'headless',
                header: 'Etes-vous sûr?',
                message: 'Cette action est irréversible!',
                accept: () => {
                    this.$dialog.open(CreateCommande, {
                        props: {
                            header: "Nouvelle commande",
                            style: {
                                width: '90vw',
                            },
                            maximizable: true,
                            breakpoints: { '1199px': '75vw', '575px': '90vw' },
                            modal: true,
                             }
                             })
                        }
                    });
        },
        openShowModal(objData) {
            this.$dialog.open(ShowCommande, {
                props: {
                    header: "Commande N°" + objData.id,
                    maximizable: true,
                    breakpoints: { '1199px': '75vw', '575px': '90vw' },
                    style: {
                        width: '90vw',
                    },
                    modal: true
                },
                data: objData
            });
        },
        getCoutAchatSum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=total_achat_cfa').then(response => {
                this.isLoading = false
                this.total_pa = response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getChiffreAffSum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=total_achat_cfa').then(response => {
                this.isLoading = false
                this.total_ChiffreAff = response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getFournisseurs() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/fournisseurs/').then(response => {
                this.fournisseurs = response.data.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })

        },
        calculerCoutTotal() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/produits-calc-compteur').then(response => {
                this.isLoading = false
                this.total_pa = response.data.cout_total_achat
                this.total_pv = response.data.cout_total_vente
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        // updateLines(nb_lines) {
        //     this.lines = nb_lines
        // },
        async updateLines(nb_lines) {
            await this.calculerCoutTotal()
            this.lines = nb_lines
        },
        searchLines(keyword) {
            this.keyWord = keyword
            this.key++
        }
    }
})
</script>

<style>
.statut {
    border: 1px solid red;
    color: red;
    background: rgb(255, 250, 250);
    padding: .2rem 1rem;
    border-radius: 100px;
}

.encours {
    border: 1px solid darkblue;
    color: #00008b;
    background: rgb(236, 236, 255);
    font-weight: 500;
    padding: .2rem 1rem;
    border-radius: 100px;
}

.termine {
    border: 1px solid green;
    color: green;
    background: rgb(249, 255, 249);
    font-weight: 700;
    padding: .2rem 1rem;
    border-radius: 100px;
}
</style>

<style>
/* .p-dialog {
      width: 100vw !important;
      height: 100vh !important;
      top: 0 !important;
      left: 0 !important;
      transform: none !important;
    } */
</style>