<template>
    <EpharmaTemplate>
        <template #page_title>STATISTIQUES</template>
        <template #page_counter>
            <i class="fa-solid fa-chart-simple" style="font-size: 25px;"></i>
        </template>
        <template #content>
            <div class="container">
               <h4 class="text-center my-3">Evolution du chiffre d'affaire</h4>
                <form class="mb-5">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4 mx-auto">
                                <label for="">Périodicité</label>
                                <select class="form-control" v-model="TypePeriode" style="
                                    border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                    background: #fafafa;" disabled>
                                    <option value="MENSUEL">MENSUEL</option>
                                    <!-- <option value="JOURNALIERE">JOURNALIERE</option>
                                    <option value="HEBDOMADAIRE">HEBDOMADAIRE</option> -->
                                </select>
                            </div>
                        </div>
                    </div>
                </form> 

                <div class="d-flex justify-content-center mb-3">
                    <ChiffreAffaire></ChiffreAffaire>
                </div>
                <div class="d-flex mb-3 row">
                    <div class="col-md-6">
                        <h3>Produits les plus vendus</h3>
                        <ProduitVendus></ProduitVendus> 
                    </div>
                    <div class="col-md-6">
                        <h3>Produits les moins vendus</h3>
                        <ProduitMoinsVendus></ProduitMoinsVendus> 
                    </div>
                </div>
            </div>
        </template>
    </EpharmaTemplate>

</template>

<script>

import ChiffreAffaire from './ChiffreAffaire.vue';
import ProduitVendus from './ProduitVendus.vue';
import ProduitMoinsVendus from './ProduitMoinsVendus.vue';

export default {
    components: { ChiffreAffaire, ProduitVendus, ProduitMoinsVendus },
    data() {
        return {
            chartData: null,
            chartOptions: null,
            TypePeriode: 'MENSUEL',
            filterForm: {}
        };
    },
    mounted() {
        this.chartData = this.setChartData();
        this.chartOptions = this.setChartOptions();
    },
    methods: {
        checkUrlFormat(link) {
            if (link !== undefined && link.includes('?')) link = link + '&'
            else link = link + '?'
            return link
        },
        periodFilter() {
            let newUrl = this.defaultUrl

            if (this.filterForm.debut) {
                newUrl = this.checkUrlFormat(newUrl) + 'from_period_debut=' + this.filterForm.debut
            }

            if (this.filterForm.fin) {
                newUrl = this.checkUrlFormat(newUrl) + 'from_period_fin=' + this.filterForm.fin
            }

            this.url = newUrl
            this.key++
        },
        setChartData() {
            const documentStyle = getComputedStyle(document.documentElement);

            return {
                labels: ['TVA', 'CSS', 'Achat', 'Benefice'],
                datasets: [
                    {
                        label: "Premier chiffre d'affaire",
                        data: [540, 325, 702],
                        hoverBackgroundColor: [documentStyle.getPropertyValue('--cyan-400'), documentStyle.getPropertyValue('--orange-400'), documentStyle.getPropertyValue('--gray-400')],
                        backgroundColor: documentStyle.getPropertyValue('--cyan-500'),
                        borderColor: documentStyle.getPropertyValue('--cyan-500'),
                        data: [65, 59, 80, 81, 56, 55, 40]
                    },
                    {
                        label: "Second chifre d'affaire",
                        backgroundColor: documentStyle.getPropertyValue('--gray-500'),
                        borderColor: documentStyle.getPropertyValue('--gray-500'),
                        data: [28, 48, 40, 19, 86, 27, 90]
                    }
                ]
            };
        },
        setChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

            return {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                plugins: {
                    legend: {
                        labels: {
                            color: textColor,
                            usePointStyle: true
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary,
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: false,
                            drawBorder: false
                        }
                    },
                    y: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder,
                            drawBorder: false
                        }
                    }
                }
            };
        }
    }
};
</script>
