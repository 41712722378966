<template>
    <EpharmaTemplate>

        <template #page_title> DEVIS</template>
        <template #page_counter>
            <i class="fas fa-receipt"></i> {{ lines }}
        </template>
        <template #content>
            <searchByKeyword class="mb-3" v-on:set-keyword-filter="searchLines" />
            <AsdecodeTable :key="key" :url="baseUrl + '/api/' + table" :columns="columns" :table="table"
                :newKeyWord="keyWord" v-on:set-total-records="updateLines">
                <template #header_action_btns>


                </template>
                <template #action_btns=slotProps>
                    <div class="d-flex">
                        <button class="btn btn-sm btn-light m-1" @click="openShowModal(slotProps.data)">
                            <i class="fa-solid fa-pen-to-square"></i>
                        </button>
                    </div>
                </template>
            </AsdecodeTable>
        </template>
    </EpharmaTemplate>
</template>

<script>

import ShowDevis from "./ShowDevis.vue";
export default {
    
    data() {
        return {
            lines: 0,
            table: 'factures',
            baseUrl: this.$store.state.api_epharma_ventes,
            columns: [
                {
                    key: 'id',
                    label: 'NUMÉRO',
                    exportable: true
                },
                {
                    key: 'created_at',
                    label: 'DATE',
                    format: 'date',
                    exportable: true
                },
                {
                    key: 'client',
                    label: 'CLIENTS',
                    exportable: true
                },
                {
                    key: 'reservation.montant',
                    label: 'NET A PAYER',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'created_by',
                    label: 'UTILISATEUR',
                    exportable: true
                }
            ],
            key: 1,
            keyWord: ''
        }
    },
    methods: {
        searchLines(keyword) {
            this.keyWord = keyword
            this.key++
        },

        openShowModal(objData) {
            this.$dialog.open(ShowDevis, {
                props: {
                    header: "Détails du devis N°" + objData.id,
                    style: {
                        width: '60vw',
                    },
                    modal: true
                },
                data: objData
            });
        },
        updateLines(nb_lines) {
            this.lines = nb_lines
        },
    }

}
</script>