<template>
    <EpharmaTemplate>
        <template #page_title> CLIENTS</template>
        <template #page_counter>
            <i class="fa-solid fa-user mr-1"></i> {{ lines }}
        </template>
        <template #content>
            <MoneyCounter :left_val="total_credit" left_label="TOTAL CREDIT" :right_val="total_dette" right_label="TOTAL DETTE"
                class="shadow bg-[#ccc]" /><br>
            <searchByKeyword class="mb-3" v-on:set-keyword-filter="searchLines" />
            <AsdecodeTable :key="key" :url="baseUrl + '/api/' + table" :columns="columns" :table="table"
                :newKeyWord="keyWord" v-on:set-total-records="updateLines" @get-table-url="refreshCounters">
                <template #header_action_btns>
                    <button class="btn btn-light mr-2" @click="openCreateModal()">
                        Nouveau client
                    </button>
                </template>
                <template #action_btns=slotProps>
                    <div class="d-flex">
                        <button class="btn btn-sm btn-light m-1" @click="openShowModal(slotProps.data)">
                            <i class="fas fa-edit"></i>
                        </button>
                    </div>
                </template>
                <template #libelle=slotProps>
                    <span v-if="slotProps.data.client_id">{{ slotProps.data.libelle }}</span>
                </template>
                <template #nom=slotProps>
                    <span v-if="slotProps.data.client_id">{{ slotProps.data.nom }}</span>
                    <span v-else>{{ slotProps.data.libelle }}</span>
                </template>
            </AsdecodeTable>
        </template>
    </EpharmaTemplate>
</template>

<script>
import MoneyCounter from '@/components/MoneyCounter.vue';
import { defineComponent, defineAsyncComponent } from 'vue';
const CreateClient = defineAsyncComponent(() => import('./CreateClient.vue'));
const EditClient = defineAsyncComponent(() => import('./EditClient.vue'));
const ShowClient = defineAsyncComponent(() => import('./ShowClient.vue'));
export default defineComponent({
    components: { MoneyCounter },
    data() {
        return {
            lines: 0,
            table: 'clients',
            baseUrl: this.$store.state.api_epharma_ventes,
            isClientTier: false,
            columns: [
                {
                    key: 'nom',
                    label: 'LIBELLE',
                    format: 'slot',
                    slotValue: '<span v-if="client_id">{{ libelle }}</span>',
                    exportable: true
                },
                {
                    key: 'remise_percent',
                    label: 'POURCENTAGE ASSURANCE',
                    exportable: true
                },
                {
                    key: 'libelle',
                    label: 'Nom du client',
                    format: 'slot',
                    exportable: true
                },
                {
                    key: 'telephone',
                    label: 'Telephone',
                    exportable: true
                },
                {
                    key: 'email',
                    label: 'Email',
                    exportable: true
                },
                {
                    key: 'ville',
                    label: 'Ville',
                    exportable: true
                },
                {
                    key: 'credit',
                    label: 'Credit',
                    exportable: true
                },
            ],
            total_credit: 0,
            total_dette: 0,
            keyCounter: 1,
            key: 1,
            keyWord: ''
        }
    },
    mounted() {
        // this.getTotalCreditSum()
        // this.getTotalDetteSum()
    },
    methods: {
        async refreshCounters(url) {
            await this.getTotalCreditSum(url)
            await this.getTotalDetteSum(url)
        },
        //fonction pour empecher les erreurs lors du filtre
        checkUrlFormat(link) {
            if (link !== undefined && link.includes('?')) link = link + '&'
            else link = link + '?'
            return link
        },
        openCreateModal() {
            this.$dialog.open(CreateClient, {
                props: {
                    header: "Nouveau client",
                    style: {
                        width: '50vw',
                    },
                    modal: true
                }
            });
        },
        openCreateModalEditClient() {
            this.$dialog.open(EditClient, {
                props: {
                    header: "libelle",
                    style: {
                        width: '60vw',
                    },
                    modal: true
                }
            });
        },
        openShowModal(objData) {
            this.$dialog.open(ShowClient, {
                props: {
                    header: "Client N°" + objData.id,
                    style: {
                        width: '60vw',
                    },
                    modal: true
                },
                data: objData
            });
        },
        updateLines(nb_lines) {
            this.lines = nb_lines
        },
        searchLines(keyword) {
            this.keyWord = keyword
            this.key++
        },
       
        // fonctions pur faire les calcules des totaux dans les compteurs
        getTotalCreditSum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=credit').then(response => {
                this.isLoading = false
                this.total_credit = response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getTotalDetteSum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=credit').then(response => {
                this.isLoading = false
                this.total_dette= response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
    }

})
</script>
