<template>
    <EpharmaTemplate :withHeader="false">
        <template #content>
            <div class="container pt-3">
                <ProgressBar class="my-1" v-if="isLoading === true" mode="indeterminate" style="height: 6px">
                </ProgressBar>
                <div class="d-flex mx-auto align-items-center mb-3">
                    <button type="button" class="btn bg-black text-white mr-4">
                        <h2>
                            N° <span v-if="selectedReservation != null" class="h2">{{ selectedReservation.id
                                }}</span>
                        </h2>
                    </button>
                    <div class="d-flex " style="max-width: 100vh; overflow-x: auto;">
                        <button type="button" class="btn rounded-circle mx-1 mb-2"
                            v-for="(reservation, index) in reservations" :key="index" :id="'reserv'+reservation.id"
                            :class="[selectedReservation != null && selectedReservation.id == reservation.id ? 'bg-white border-primary' : 'bg-white border-light']"
                            @click="select_reservation(reservation, reservations.length - index)">
                            {{ reservations.length - index }}
                        </button>
                    </div>
                    <div class="d-flex ml-auto">
                        <button type="button" class="btn" @click="getReservations()">
                            <i class="fa-solid fa-rotate text-black bg-white p-2 rounded-circle" title="Rafraichir"
                                style="font-size:25px"></i>
                        </button>

                        <button type="button" class="btn mr-4" style="width: 50px; max-width: 50px;" @click="logout()">
                            <i class="fa-solid fa-right-to-bracket text-red1" title="Se déconnecter "
                                style="font-size:25px"></i>
                        </button>
                    </div>
                </div>

                <div class="row d-flex justify-content-center" v-if="isLoading === false && selectedReservation">
                    <div class="col-md-8 h-fixed ">
                        <div class="position-relative">
                            <div id="overlay" class="overlay" v-if="selectedReservation.status == 'Termine'"></div>
                            <div class="watermark border-danger px-5" v-if="selectedReservation.status == 'Termine'"> Payé Livré
                            </div>

                            <div class="card bg-white mb-3 pb-3 shadow ">
                                <div class="bg-bleuN m-3" v-if="caisse != null">
                                    <h6 class="text-center p-4">{{ caisse.libelle }}</h6>
                                </div>
                                <div class="col-md-4 form-group">
                                    <label>Client</label>
                                    <input type="text" :value="selectedReservation.client" class="form-control " style=" border-bottom: 1px solid black ; 
                                        border-left: none; border-right: none; border-top: none; 
                                    background: #fafafa;" disabled>
                                </div>
                            </div>
                            <div class="card body-bg-white shadow">
                                <table class="table table-striped" :key="key">
                                    <thead class="bg-primary text-light">
                                        <tr>
                                            <th scope="col">PRODUIT</th>
                                            <th scope="col">QUANTITE</th>
                                            <th scope="col">COÛT</th>
                                            <th scope="col">% TAUX</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in reservation_produits" :key="index">
                                            <td>{{ item.libelle }}</td>
                                            <td>{{ item.qte }}</td>
                                            <td>{{ moneyFormat(item.cout_total) }}</td>
                                            <td>{{ item.prise_en_charge }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <form class="col-md-4" @submit.prevent="updateReservation">
                        <div class="d-flex justify-content-between">
                            <button type="submit" class="btn btn-black" v-if="!selectedReservation.switch_finish_at">
                                Payer
                            </button>

                            <button type="submit" class="btn btn-black" v-if="selectedReservation.status == 'Termine'" @click="this.exportPDF()">
                                Imprimer
                            </button>

                            <button type="button" class="btn btn-black" @click="openBilletage()">
                                Billetage
                            </button>
                        </div>
                        <div class="bg-black rounded-sm mt-3 p-2  ">
                            <label class="text-white "> TTC</label>
                            <h5 class="text-white">{{ moneyFormat(selectedReservation.montant) }}</h5>
                        </div>
                        <div class="bg-black mt-3 rounded-sm p-3">
                            <div class="form-group">
                                <label class="text-gri3">Montant reçu</label>
                                <input type="number" v-model="form.amount_gived" class="form-control" required
                                    :disabled="selectedReservation.switch_finish_at">
                            </div>
                            <div class="form-group">
                                <label class="text-gri3">Difference</label>
                                <input type="number"
                                    :value="(parseFloat(form.amount_gived) - parseFloat(selectedReservation.montant)).toFixed(2)"
                                    class="form-control" step="0.00" disabled>
                            </div>
                        </div>
                    </form>
                </div>

                <div v-else class="col-md-4 my-3 mx-auto">
                    <button type="button" class="btn btn-black" @click="openBilletage()">
                        Billetage
                    </button>
                </div>
                <div hidden class="my-5 col-md-4" v-if="selectedReservation && caisse">
                    <div id="ticket" class="container-fluid">
                        <div class="d-flex justify-content-center">
                            <img src="/img/epharma-sf.png " alt="" style="width: 100px;" id="imglogo2epharma">
                        </div>
                        <p class="text-center">
                            <small>LIBREVILLE-NKEMBO,RCCM:2010B08828, NIF:
                                07078800, avoilenzme@gmail.com Tél:0234567
                            </small>
                        </p>

                        <div style="font-size: 10px; line-height: 1">
                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">N°</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Caisse</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{ selectedReservation.position }}</td>
                                        <td>{{ new Date().toLocaleString('fr-FR', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit'
                                        })}}</td>
                                        <td class="d-flex justify-content-end">{{ caisse.libelle }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">Qté</th>
                                        <th scope="col">Libelle</th>
                                        <th scope="col">P.U.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in reservation_produits" :key="index">
                                        <td>{{ item.qte }}</td>
                                        <td>{{ item.libelle }}</td>
                                        <td class="d-flex justify-content-end">{{ item.prix_de_vente }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div class="d-flex justify-content-between dashed-line py-2">
                                <span>TTC</span>
                                <span>{{ selectedReservation.montant }}</span>
                            </div>
                            <div v-if="selectedReservation.prise_en_charge">
                                <div class="d-flex justify-content-between dashed-line py-2">
                                    <span>Pris en charge</span>
                                    <span>{{ ((selectedReservation.prise_en_charge) / 100 *
                                        parseFloat(selectedReservation.montant)) }}</span>
                                </div>
                                <div class="d-flex justify-content-between dashed-line py-2">
                                    <span>Ticket modérateur</span>
                                    <span>{{ parseFloat(selectedReservation.montant) -
                                        ((selectedReservation.prise_en_charge) / 100 *
                                        parseFloat(selectedReservation.montant)) }}</span>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between dashed-line py-2">
                                <span>Montant reçu</span>
                                <span>{{ parseFloat(form.amount_gived) }}</span>
                            </div>
                            <div class="d-flex justify-content-between dashed-line py-2">
                                <span>Reste</span>
                                <span v-if="selectedReservation.prise_en_charge">{{ (parseFloat(form.amount_gived) -
                                    (parseFloat(selectedReservation.montant) -
                                        ((selectedReservation.prise_en_charge)/100 *
                                    parseFloat(selectedReservation.montant)))).toFixed(2) }}</span>
                                <span v-else>{{ (parseFloat(form.amount_gived) -
                                    parseFloat(selectedReservation.montant)).toFixed(2) }}</span>
                            </div>
                        </div>
                        <p class="text-center font-weight-bold mt-3">
                            Les produits vendus ne sont ni repris, ni échangés Merci et bonne guérison
                        </p>

                        <p class="text-center">
                            Rien ne vaut la sante bonne guerison!
                        </p>


                    </div>
                </div>
            </div>
        </template>
    </EpharmaTemplate>
</template>

<script>
import { defineComponent, defineAsyncComponent } from 'vue';
import html2pdf from "html2pdf.js";
const CreateBilletage = defineAsyncComponent(() => import('../../gestion/billetages/CreateBilletage.vue'));

export default defineComponent({
    data() {
        return {
            key: 1,
            table: 'reservations',
            baseUrl: this.$store.state.api_epharma_ventes,
            isLoading: false,
            selectedReservation: null,
            form: {},
            caisse: null,
            reservation_produits: []
        }

    },
    beforeCreate() {
        if (this.$store.getters.caisseConnected != this.$route.params.id || !this.$store.getters.caisseAuthenticated) {
            this.$router.push({ name: 'caisse' })
        }
    },
    created() {
        this.getCaisse()
    },
    mounted() {
        this.getReservations()
    },
    methods: {
        getProduits() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/reservation_produits?reservation_id=' + this.selectedReservation.id).then((response) => {
                this.reservation_produits = response.data.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })

        },
        logout() {
            this.$store.commit('clearAuthenticatedCaisse')
            this.$router.push({ name: 'caisse' })
        },
        openBilletage() {
            this.$dialog.open(CreateBilletage, {
                props: {
                    header: "Enregistrement du billetage de la caisse " + this.caisse.libelle,
                    style: {
                        width: '80vw',
                    },
                    modal: true
                },
                data: this.caisse
            });
        },
        moneyFormat(varMoney) {
            if (varMoney) return new Intl.NumberFormat('de-DE').format(parseFloat(varMoney)) + ' FCFA'
        },
        getCaisse() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/caisses/' + this.$route.params.id).then((response) => {
                this.caisse = response.data.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getReservations() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/reservations/jour' + '?caisse_id=' + this.$route.params.id).then((response) => {
                this.reservations = response.data.data
                if (this.reservations.length > 0) {
                    this.selectedReservation = this.reservations[this.reservations.length - 1];
                    this.selectedReservation.position = 1
                    this.form = {
                        status:  this.selectedReservation.amount_gived,
                        amount_gived:  this.selectedReservation.amount_gived,
                        switch_finish_at:  this.selectedReservation.switch_finish_at
                    }
                    this.getProduits()
                    this.key++
                }
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        select_reservation(reservation, position) {
            this.selectedReservation = reservation
            this.selectedReservation.position = position
            this.form = {
                status: reservation.amount_gived,
                amount_gived: reservation.amount_gived,
                switch_finish_at: reservation.switch_finish_at
            }
            this.getProduits()
            this.key++
        },
        exportPDF() {
            this.isLoading = true
            html2pdf().set({
                pagebreak: { mode: 'avoid-all' },
                jsPDF: { unit: 'in', format: 'a6', orientation: 'portrait' },
                margin: [0.1, 0.1],
                filename: "Ticket de paiement-" + this.selectedReservation.id,
                html2canvas: { scale: 2 },
                image: { type: 'jpeg', quality: 0.98 },
            }).from(document.getElementById("ticket"))
                .toPdf()
                .get('pdf')
                .then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();
                    pdf.setFontSize(10);
                    pdf.setTextColor(150);
                    for (var i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        // pdf.text(' S.A.R.L au capital de 1.000.000 F CFA | Oloumi - BP: 1073 Libreville | Tel: +241 (0) 1 74 78 91 - 062 13 71 78 ' + "\n" + 'RCCM: GA-LBV-01-2020-B13-00255 / NIF: 054889 C  |Email: infos@yamslogistics.com / Site web: yamslogistics.com', pdf.internal.pageSize.getWidth() - 0.50, pdf.internal.pageSize.getHeight() - 0.50, { align: 'right' });
                    }
                })
                .save();
            setTimeout(() => {
                this.isLoading = false
            }, 5000)
        },
        closeReservation() {
            this.selectedReservation.switch_finish_at = new Date().toLocaleString('fr-FR')
            this.exportPDF()
            this.getReservations()
        },
        async updateReservation() {
            if ((parseFloat(this.form.amount_gived) - parseFloat(this.selectedReservation.montant)) < 0) {
                alert('Le difference ne peut etre negative');
                return
            }

            this.form.status = 'Termine'
            this.form.switch_finish_at = new Date().toLocaleString('fr-FR')
            this.form.switch_finish_by = this.$store.getters.user.fullname

            await this.axios.post(`${this.baseUrl}/api/reservations/${this.selectedReservation.id}/update`, this.form).then(response => {
                this.isLoading = false
                if (response.data.success === true) {
                    this.closeReservation()
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        }
    }
})
</script>

<style scoped>
.h-fixed {
    height: 80vh;
    overflow-y: auto;
}

.card .position-relative {
    position: relative;
}

.overlay {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(128, 128, 128, 0.2);
    /* Grise l'arrière-plan */
    z-index: 1050;
    pointer-events: all;
    /* Désactive les interactions sur les éléments sous l'overlay */
}

.watermark {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(30deg);
    border-style: dashed;
    color: #ff0022;
    font-size: 2.1em;
    font-weight: bold;
    opacity: 0.5;
    z-index: 1060;
    /* z-index: 1; */
    pointer-events: none;
    /* Désactive les clics sur le filigrane */
}
</style>
