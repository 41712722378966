<template>
    <EpharmaTemplate>

        <template #page_title> FIN DE JOURNÉE </template>
        <template #page_counter>
            <i class="fa-solid fa-money-bill"></i> 10
        </template>
        <template #content>
            <form @submit.prevent="periodFilter" class="row border shadow-lg rounded p-3 mx-auto mb-2"
                style="opacity: 0.7;">
                <div class="form-group col-sm">
                    <div>
                        <small class="font-weight-bold pl-3">Rechercher par date</small>
                        <div>
                            <label></label>
                            <input class="form-control form-control-sm" type="date" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;" v-model="filterForm.debut">
                        </div>
                    </div>
                </div>
                <div class="form-group col-sm">
                    <div>
                        <small class="font-weight-bold pl-3"></small>
                        <div>
                            <label></label>
                            <input class="form-control form-control-sm" type="date" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;" v-model="filterForm.fin">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 mt-5">
                    <button type="submit" class="btn">
                        <i class="fa-solid fa-circle-check text-success" style="font-size: 1.6rem;"
                            title="Appliquer le filtrage"></i>
                    </button>
                    <button type="button" class="btn" @click="removeFilter()">
                        <i class="fa-solid fa-circle-minus" style="font-size: 1.6rem; color: #28a745;"
                            title="Annuler le filtrage"></i>
                    </button>
                </div>
            </form>
            <AsdecodeTable :key="key"
                :url="baseUrl + '/api/billetages?from_period_debut=' + this.filterForm.debut + '&from_period_fin=' + this.filterForm.fin"
                :columns="columns">
            </AsdecodeTable><br><br>
            <button hidden id="refreshcounter" type="button" @click="refreshCounters">Raffraichir le compteur</button>
            <MoneyCounterDeux :key="keyCounter" :left_val="total_client" :left_val1="total_pc"
                left_label="CLIENT CFA-ASSURANCE CFA" :right_val="total_ca" :right_val1="total_ht"
                :right_val2="total_tva" :right_val3="total_css" right_label="CHIFFRE D'AFFAIRE TOTAL-HT-TVA-CSS" /><br>

            <AsdecodeTable :key="key"
                :url="baseUrl + '/api/ventes?from_period_debut=' + this.filterForm.debut + '&from_period_fin=' + this.filterForm.fin"
                :columns="columns1" @get-table-url="refreshCounters">
                <template #header_action_btns>
                    <button style="border:none">
                        Recapitulatif CNAMGS
                    </button>
                </template>
            </AsdecodeTable><br><br>
            <p style="font-weight: bold;">Produits vendus dans la période</p>
            <AsdecodeTable :key="key"
                :url="baseUrl + '/api/reservation_produits/vendus?from_period_debut=' + this.filterForm.debut + '&from_period_fin=' + this.filterForm.fin"
                :columns="columns2" style="margin-bottom: 5%;">
                <template #action_btns=slotProps>
                    <div class="d-flex">
                        <button class="btn btn-sm btn-light m-1" @click="openShowModal(slotProps.data)">
                        </button>
                    </div>
                </template>
                <template #id="slotProps">
                    <button class="btn btn-sm btn-primary">
                        <i class="fa-solid fa-magnifying-glass mr-1"></i> Trouver les ventes
                    </button>
                </template>
            </AsdecodeTable>

            <searchByKeyword class="mb-3" v-on:set-keyword-filter="searchLines" /><br>
            <form @submit.prevent="mouvementFilter" class="border shadow-lg rounded p-3 mx-auto" style="opacity: 0.7;">
                <div class="form-row">
                    <div class="form-group col-lg">
                        <div>
                            <small class="font-weight-bold">Rechercher par motif</small>
                            <div>
                                <label></label>
                                <select class="form-control form-control-sm" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;" v-model="filterForm.motif">
                                    <option v-for="(motif, index) in motifList" :key="index" :value="motif">{{ motif }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="form-group col-lg">
                        <div>
                            <small class="font-weight-bold">Rechercher par type</small>
                            <div>
                                <label></label>
                                <select class="form-control form-control-sm" style="
                                   border-bottom: 1px solid black; border-left: none; border-right: none; border-top: none; 
                                   background: #fafafa;" v-model="filterForm.type">
                                    <option value="Entrée">Entrée</option>
                                    <option value="Sortie">Sortie</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 mt-5">
                        <button type="submit" class="btn">
                            <i class="fa-solid fa-circle-check text-success" style="font-size: 1.6rem;"
                                title="Appliquer le filtrage"></i>
                        </button>
                        <button type="button" class="btn" @click="removeFilter()">
                            <i class="fa-solid fa-circle-minus" style="font-size: 1.6rem; color: #28a745;"
                                title="Annuler le filtrage"></i>
                        </button>
                    </div>
                </div>
            </form><br>
            <AsdecodeTable :key="key" :newKeyWord="keyWord" :url="baseUrl1 + url" :columns="columns3"
                :v-on:set-total-records="updateLines">

            </AsdecodeTable>
        </template>
    </EpharmaTemplate>

</template>

<script>

import { defineComponent } from 'vue';
import moment from 'moment';
import { motif } from '../../stock/produits/resources/motif.js'
import MoneyCounterDeux from '@/components/MoneyCounterDeux.vue';
    
export default defineComponent({
    components: { MoneyCounterDeux },
    data() {
        return {
            lines: 0,
            table: 'billetages',
            table1: 'ventes',
            table3: 'reservation_produits',
            baseUrl: this.$store.state.api_epharma_ventes,
            table2: 'mouvements',
            baseUrl1: this.$store.state.api_epharma_stock,
            defaultUrl: '/api/mouvements?from_period_debut=' + moment().format('YYYY-MM-DD') + '&from_period_fin=' + moment().add(1, 'days').format('YYYY-MM-DD'),
            url: '/api/mouvements?from_period_debut=' + moment().format('YYYY-MM-DD') + '&from_period_fin=' + moment().add(1, 'days').format('YYYY-MM-DD'),
            columns: [
                {
                    key: 'caisse_libelle',
                    label: 'CAISSE',
                    exportable: true
                },
                {
                    key: 'ended_with',
                    label: 'CLÔTURÉ PAR',
                    exportable: true
                },
                {
                    key: 'total_vente',
                    label: 'TOTAL VENTE',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'total_billetage',
                    label: 'TOTAL BILLETAGE',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'ecart',
                    label: 'ECART',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'created_at',
                    label: 'PÉRIODE',
                    format: 'date',
                    exportable: true
                },
                {
                    key: 'statut',
                    label: 'STATUT',
                    exportable: true
                },


            ],

            columns1: [
                {
                    key: 'caisse',
                    label: 'CAISSE',
                    exportable: true
                },
                {
                    key: 'user',
                    label: 'VENDEUSE',
                    exportable: true
                },
                {
                    key: 'statut',
                    label: 'STATUS',
                    exportable: true
                },
                {
                    key: 'client',
                    label: 'CLIENT',
                    exportable: true
                },
                {
                    key: 'reservation_id',
                    label: 'RESERVATION',
                    exportable: true
                },
                {
                    key: 'total',
                    label: 'TTC',
                    exportable: true
                },
                {
                    key: 'ht',
                    label: 'HT',
                    exportable: true
                },
                {
                    key: 'tva',
                    label: 'TVA',
                    exportable: true
                },
                {
                    key: 'css',
                    label: 'CSS',
                    exportable: true
                },
                {
                    key: 'total',
                    label: 'TOTAL CLIENT FCFA',
                    exportable: true
                },
                {
                    key: 'total_prise_en_charge',
                    label: 'TOTAL PRIS EN CHARGE FCFA',
                    exportable: true
                }

            ],

            columns2: [
                {
                    key: 'produit.cip',
                    label: 'CIP',
                    exportable: true
                },
                {
                    key: 'libelle',
                    label: 'LIBELLE',
                    exportable: true
                },
                {
                    key: 'qte_vendus',
                    label: 'QUANTITE VENDU',
                    exportable: true
                },
                {
                    key: 'qte_actu',
                    label: 'STOCK ACTUEL',
                    exportable: true
                },
                {
                    key: 'id',
                    label: 'VENTES',
                    format: 'slot',
                    exportable: true
                },
            ],

            columns3: [
                {
                    key: 'produit.libelle',
                    label: 'PRODUIT',
                    exportable: true
                },
                {
                    key: 'type',
                    label: 'CATEGORIE',
                    exportable: true
                },
                {
                    key: 'qte',
                    label: 'QUANTITE',
                    exportable: true
                },
                {
                    key: 'produit.prix_achat',
                    label: 'PRIX ACHAT',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'produit.prix_de_vente',
                    label: 'PRIX VENTE',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'created_at',
                    label: 'DATE',
                    format: 'date',
                    exportable: true
                },
                {
                    key: 'motif',
                    label: 'MOTIF',
                    exportable: true
                },
                {
                    key: 'created_by',
                    label: 'UTILISATEUR',
                    exportable: true
                }
            ],
            total_client: 0,
            total_pc: 0,
            total_tva: 0,
            total_css: 0,
            total_ht: 0,
            total_ca: 0,
            key: 1,
            keyWord: '',
            keyCounter: 1,
            filterForm: {
                debut: moment().format('YYYY-MM-DD'),
                fin: moment().add(1, 'days').format('YYYY-MM-DD')
            },

        }

    },
    created() {
        this.motifList = motif;
    },
    methods: {
        async refreshCounters(url) {
            await this.getTotalClientSum(url)
            await this.getPriseEnChargeSum(url)
            await this.getCASum(url)
            await this.getHTSum(url)
            await this.getTVASum(url)
            await this.getCssSum(url)
        },
        //fonction pour empecher les erreurs lors du filtre
        checkUrlFormat(link) {
            if (link !== undefined && link.includes('?')) link = link + '&'
            else link = link + '?'
            return link
        },
        dateFormat(varDate) {
            if (varDate != '') {
                const newDate = new Date(varDate)
                if (newDate) return moment(newDate).format('DD/MM/YYYY HH:mm:ss')
            }
        },
        checkUrlFormat(link) {
            if (link !== undefined && link.includes('?')) link = link + '&'
            else link = link + '?'
            return link
        },
        periodFilter() {
            let newUrl = this.defaultUrl

            if (this.filterForm.debut) {
                newUrl = this.checkUrlFormat(newUrl) + 'from_period_debut=' + this.filterForm.debut
            }

            if (this.filterForm.fin) {
                newUrl = this.checkUrlFormat(newUrl) + 'from_period_fin=' + this.filterForm.fin
            }
            this.url = newUrl
            this.key++
        },
        mouvementFilter() {
            let newUrl = this.defaultUrl

            if (this.filterForm.motif) {
                newUrl = this.checkUrlFormat(newUrl) + 'motif=' + this.filterForm.motif
            }

            if (this.filterForm.type) {
                newUrl = this.checkUrlFormat(newUrl) + 'type=' + this.filterForm.type
            }

            this.url = newUrl
            this.key++
        },
        removeFilter() {
            this.filterForm = {
                debut: moment().format('YYYY-MM-DD'),
                fin: moment().add(1, 'days').format('YYYY-MM-DD')
            }
            this.url = this.defaultUrl
            this.key++
        },
        updateLines(nb_lines) {
            this.lines = nb_lines
        },
        searchLines(keyword) {
            this.keyWord = keyword
            this.key++
        },
        // fonctions pour calculer les totaux dans le compteur
        getTotalClientSum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=total').then(response => {
                this.isLoading = false
                this.total_client = response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getPriseEnChargeSum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=total_prise_en_charge').then(response => {
                this.isLoading = false
                this.total_pc= response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getCASum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=total').then(response => {
                this.isLoading = false
                this.total_ca = response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getHTSum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=ht').then(response => {
                this.isLoading = false
                this.total_ht = response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getTVASum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=tva').then(response => {
                this.isLoading = false
                this.total_tva = response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        getCssSum(tableUrl) {
            this.isLoading = true
            this.axios.get(this.checkUrlFormat(tableUrl) + 'column_sum=css').then(response => {
                this.isLoading = false
                this.total_css = response.data
                this.keyCounter++
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
    }

})
</script>