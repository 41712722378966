<template>
    <div class="row justify-content-center ">
       
        <div class=" m-3">
            <p>Client</p>
            <p class="text-bleuClair">{{  this.dialogRef.data.client}}</p>
        </div>
        <div class=" m-3">
            <p>Reservation</p>
            <p class="">N°{{  this.dialogRef.data.reservation_id }}</p>
            <p class="text-bleuClair">{{  this.dialogRef.data.user }}</p>
        </div>
        <div class=" m-3">
            <p>caisse</p>
            <p class="">{{this.dialogRef.data.caisse }}</p>
            <p class="text-bleuClair">{{  this.dialogRef.data.user }}</p>
        </div>
    </div>
        <div>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="" colspan="4" >
                            <button  class="btn btn bg-OrangeVente text-white">
                                Annuler la vente
                            </button>
                        </th>
                      
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row" colspan="4">TTC</th>
                        <td>{{this.dialogRef.data.total}}</td>
                       
                    </tr>
                    <tr>
                        <th scope="row" colspan="4">Montant reçu</th>
                        <td>{{ this.dialogRef.data.montant_recu }}</td>
                       
                    </tr>
                    <tr>
                        <th scope="row" colspan="4">Montant rendu</th>
                        <td>{{ this.dialogRef.data.is_sold }}</td>
                       
                    </tr>

                </tbody>
            </table>
        </div>
        <div>
            <table class="table table-bordered" >
                <thead class="">
                    <tr>
                        <th scope="col">PRODUIT</th>
                        <th scope="col">QUANTITE</th>
                        <th scope="col">COÛT</th>
                        <th scope="col">% TAUX</th>
                    </tr>
                </thead>
                <tbody>
                        <tr v-for="item in reservation_produits" :key="index">
                            <td>{{ item.libelle }}</td>
                            <td>{{ item.qte }}</td>
                            <td>{{ moneyFormat(item.cout_total) }}</td>
                            <td>{{ item.prise_en_charge }}</td>
                            <!-- <td>{{ this.dialogRef.data.total_prise_en_charge }}%</td> -->
                    </tr>
                </tbody>
            </table>
        </div>


   
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    inject: ['dialogRef'],
    data(){
        return {
            key:0,
            table: 'ventes',
            reservation_produits: [],
            baseUrl: this.$store.state.api_epharma_ventes,
           
            
        }
    },
    mounted() {
        this.getProduits()
    },
    methods: {
        getProduits() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/reservation_produits?reservation_id=' + this.dialogRef.data.reservation_id).then((response) => {
                this.reservation_produits = response.data.data
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
    },
    moneyFormat(varMoney) {
            if (varMoney) return new Intl.NumberFormat('de-DE').format(parseFloat(varMoney)) + ' FCFA'
        },
     }
})

</script>

