<template>
    <SectionVisibility libelleSection="la selection des produits" :isOpen="true">
        <template #section_content>
            <div v-if="this.reservation" class="card card-body p-3 mb-3 position-relative">
                <div class="form-group">
                    <input type="search" class="form-control form-control-lg m-0" placeholder="Tapez le mot clé à rechercher" v-model="keyWord" @input="key++">
                </div>
                <AsdecodeTable v-if="keyWord != ''" :key="key" noExport :url="this.$store.state.api_epharma_stock + '/api/produits?is_active=1'"
                    :columns="columns" :newKeyWord="keyWord" table="produits" v-on:set-total-records="updateLines">
                    <template #header_action_btns>
                        <button v-if="produits_selected.length > 0" class="btn btn-light text-secondary mr-2" disabled>
                            {{ produits_selected.length }} produits sélectionnés
                        </button>
                    </template>
                    <template #action_btns=slotProps>
                        <button v-if="produits_selected.includes(slotProps.data.id)" class="btn btn-danger btn-sm  m-1"
                            @click="removeProduit(slotProps.data)" title="Retirer">
                            <i class="fa-solid fa-trash-alt"></i> 
                        </button>
                        <button v-else-if="!produits_selected.includes(slotProps.data.id) && slotProps.data.qte > 0"
                            type="button" class="btn btn-success btn-sm  m-1" @click="addProduit(slotProps.data)" title="Ajouter">
                            <i class="fa-solid fa-plus"></i> 
                        </button>
                        <button type="button" class="btn btn-secondary btn-sm" @click="commandProduit(slotProps.data.id)" title="Commander">
                            <i class="fa-solid fa-cart-arrow-down"></i>
                        </button>
                    </template>
                    <template #libelle=slotProps>
                        {{ slotProps.data.libelle }}
                        <span class="text-vert ml-2" v-if="slotProps.data.tva == '1'">TVA</span>
                        <span class="text-bleu3 ml-2" v-if="slotProps.data.css == '1'">CSS</span>
                    </template>
                </AsdecodeTable>
            </div>
        </template>
    </SectionVisibility>
</template>

<script>
import $ from 'jquery'
import { defineComponent } from 'vue';

export default defineComponent({
    props: ['reservation', 'garde'],
    data() {
        return {
            lines: 0,
            isLoading: false,
            key: 1,
            keyWord: '',
            table: 'reservation_produits',
            baseUrl: this.$store.state.api_epharma_ventes,
            columns: [
                {
                    key: 'libelle',
                    label: 'NOM',
                    format: 'slot',
                    exportable: true
                },
                {
                    key: 'prix_de_vente',
                    label: 'P.U.',
                    format: 'money',
                    exportable: true
                },
                {
                    key: 'cip',
                    label: 'CIP',
                    exportable: true
                },
                {
                    key: 'qte',
                    label: 'QT. ACTU',
                    exportable: true,
                    
                },
                {
                    key: 'rayon.libelle',
                    label: 'RAYON',
                    exportable: 'true'

                }
            ],
            produits_selected: [],
        }
    },
    mounted() {
        this.initSelection()
    },
    methods: {
        commandProduit(id) {
            if (!window.confirm("Etes-vous sur de vouloir commander ce produit ?")) {
                return
            }
            this.isLoading = true
            this.axios.post(this.$store.state.api_epharma_stock + '/api/commandes/ajouter-produit/'+id).then(response => {
                if (response.data.success === true) {
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        initSelection() {
            this.isLoading = true
            this.axios.get(this.baseUrl + '/api/' + this.table + '?reservation_id=' + this.reservation.id).then(response => {
                response.data.data.forEach(element => {
                    this.produits_selected.push(element.produit.id)
                });
                this.isLoading = false
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        addProduit(line) {
            this.produits_selected.push(line.id)
            this.updateReservationProduit(line)
        },
        removeProduit(line) {
            this.produits_selected = this.produits_selected.filter(item => item != line.id)
            this.removeReservationProduit(line.id)
        },
        async updateReservationProduit(line) {
            this.isLoading = true

            let total = parseFloat(line.prix_de_vente)
            if (parseFloat( this.reservation.prise_en_charge) > 0) {
                total =  total - ( total * (parseFloat(this.reservation.prise_en_charge)/100)) 
            }

            let form = {
                'produit_qte': line.qte,
                'reservation_id': this.reservation.id,
                'prise_en_charge': this.reservation.prise_en_charge,
                'produit': line,
                'produit_id': line.id,
                'prix_de_vente': line.prix_de_vente,
                'cout_total': total,
                'qte': 1,
                'libelle': line.libelle,
            }

            if (this.garde != null && parseFloat(this.garde.montant_taxe)) {
                form.prix_de_vente = parseFloat(form.prix_de_vente) + parseFloat(this.garde.montant_taxe)
            }

            if (line.tva == true) {
                form.total_tva = ((parseFloat(form.prix_de_vente) * this.$store.getters.tva) / (1 + this.$store.getters.tva)).toFixed(2)
                form.total_ht = (parseFloat(form.prix_de_vente) - parseFloat(form.total_tva)).toFixed(2)
            }

            if (line.css == true) {
                form.total_css = ((parseFloat(form.prix_de_vente) * this.$store.getters.css) / (1 + this.$store.getters.css)).toFixed(2)
                form.total_ht = (parseFloat(form.total_ht) -  parseFloat(form.total_css)).toFixed(2)
            }
            
            if (this.reservation.prise_en_charge) {
                form.total_prise_en_charge= (parseFloat(line.prix_de_vente) * parseFloat(this.reservation.prise_en_charge / 100)).toFixed(2)
            }

            await this.axios.post(this.baseUrl + '/api/' + this.table, form).then(response => {
                this.isLoading = false
                if (response.data.success === true) {
                    $('#refresh' + this.table).click()
                    $('#refreshreservationmontant').click()
                    this.$emit('set-products', this.produits_selected.length)
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        async removeReservationProduit(produitId) {
            this.isLoading = true
            let form = {
                reservation_id: this.reservation.id 
            }
            await this.axios.post(this.baseUrl + '/api/' + this.table + '/' + produitId + '/destroy2', form).then(response => {
                this.isLoading = false
                if (response.data.success === true) {
                    $('#refresh' + this.table).click()
                    $('#refreshreservationmontant').click()
                    this.$emit('set-products')
                    this.$toast.add({
                        severity: 'success',
                        detail: response.data.message,
                        life: 3000
                    });
                }
                else {
                    response.data.errors.forEach(element => {
                        this.$toast.add({
                            severity: 'warn',
                            summary: 'Oups !',
                            detail: element,
                            life: 7000
                        });
                    });
                }
            }).catch(() => {
                this.isLoading = false
                this.$toast.add({
                    severity: 'error',
                    summary: 'Probleme de connexion',
                    detail: 'Une erreur s\'est produite lors de la connexion au serveur !',
                    life: 5000
                });
            })
        },
        updateLines(lines) {
            this.lines = lines
        }
    },
})
</script>