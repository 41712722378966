<template>
    <div class="card" style="width: 70rem;">
        <div class="card-body">
           <ChartJS type="line" :data="chartData" :options="chartOptions" class="h-50rem" /> 
        </div>
    </div>
</template>

<script>

export default {
    name: 'ChiffreAffaireMensuelLineChart',
    data() {
        return {
            baseUrl: this.$store.state.api_epharma_ventes,
            chartData: {
                labels: [], // Libellés des mois
                datasets: [
                    {
                        label: 'Chiffre d\'affaires mensuel (FCFA)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 2,
                        fill: true,
                        data: [] // Totaux des ventes
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Mois'
                        }
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Montant (FCFA)'
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top'
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false
                    }
                }
            }
        };
    },
    methods: {
        fetchData() {
            this.axios.get(this.baseUrl + '/api/stat/chiffre-affaire') // Remplacer par l'endpoint approprié
                .then(response => {
                    const data = response.data;

                    // Remplir les labels et les données pour le graphique
                    this.chartData.labels = data.map(item => item.mois); // Libellés des mois
                    this.chartData.datasets[0].data = data.map(item => item.total); // Totaux par mois
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération des données du chiffre d'affaires :", error);
                });
        }
    },
    mounted() {
        this.fetchData(); // Récupérer les données lors du montage du composant
    }
};
</script>
