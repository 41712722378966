<template>
    <div class="card" >
        <div class="card-body">
            <ChartJS type="bar" :data="chartData" :options="chartOptions"></ChartJS>
        </div>
        
    </div>
</template>

<script>

export default {
    name: 'TopProduitsChart',
    data() {
        return {
            baseUrl: this.$store.state.api_epharma_ventes,
            chartData: {
                labels: [], // Libellés des produits
                datasets: [
                    {
                        label: 'Quantité vendue',
                        backgroundColor: '#42A5F5',
                        borderColor: '#1E88E5',
                        borderWidth: 1,
                        data: [] // Quantités vendues
                    }
                ]
            },
            chartOptions: {
                responsive: true,
                indexAxis: 'y', // Cette option permet de rendre le graphique horizontal
                scales: {
                    x: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Quantité vendue'
                        }
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Produits'
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false // On masque la légende pour ce graphique
                    },
                    tooltip: {
                        mode: 'index',
                        intersect: false
                    }
                }
            }
        };
    },
    methods: {
        fetchTopProduits() {
            this.axios.get(this.baseUrl + '/api/stat/top20-produits-vendus') // Remplacer par l'endpoint de ton API
                .then(response => {
                    const data = response.data;

                    // Remplir les labels (noms des produits) et les données (quantités vendues)
                    this.chartData.labels = data.map(item => item.libelle); // Libellés des produits
                    this.chartData.datasets[0].data = data.map(item => item.total_vendu); // Quantités vendues
                })
                .catch(error => {
                    console.error("Erreur lors de la récupération des données des produits :", error);
                });
        }
    },
    mounted() {
        this.fetchTopProduits(); // Récupérer les données lors du montage du composant
    }
};
</script>

<style scoped>
.chart-container {
    position: relative;
    height: 400px;
    width: 100%;
}
</style>