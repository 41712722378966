<template>
    <div class="card mx-auto border-bleugain shadow">
        <div class="card-body row pt-2 pb-0 px-3">
            <div class="card-gain col">
                <h4 class="border-bottom text-bleugain">{{ moneyFormat(this.left_val) }}- {{ moneyFormat(this.left_val1) }} </h4>
                <p class="text-right font-weight-bold">{{ this.left_label }}</p>
            </div>
            <div class="card-gain col">
                <h4 class="border-bottom text-bleugain">{{ moneyFormat(this.right_val) }}-{{moneyFormat(this.right_val1)  }}-{{ moneyFormat(this.right_val2)}}-{{ moneyFormat(this.right_val3) }}</h4>
                <p class="text-right font-weight-bold">{{ this.right_label }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"MoneyCounterDeux",
        props: [ 'left_val','left_val1', 'left_label', 'right_val', 'right_val1', 'right_val2', 'right_val3', 'right_label' ],
        methods: {
            moneyFormat(varMoney) {
                if (varMoney) return new Intl.NumberFormat('de-DE').format(varMoney) + ' FCFA'
            },
        },
    }
</script>